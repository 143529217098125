import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HomePopupModel } from '../../models/data/global/home-popup.model';

@Injectable({
    providedIn: 'root'
})
export class HomePopupService {
    constructor(
        private http: HttpClient
    ) { }

    getList(): Observable<HomePopupModel[]> {
        const url = environment.apiUrl + '/api/cms/HomePopupConfigurations/HomePopupConfiguration/';
        return this.http.get<HomePopupModel[]>(url);
    }
}

