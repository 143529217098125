import { Component, ViewChild } from '@angular/core';
import { Router, NavigationEnd, NavigationError } from '@angular/router';
import * as rg4js from 'raygun4js';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HomePopupService } from './core/services/global/home-popup.service';
import { HomePopupModel } from './core/models/data/global/home-popup.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  hp: HomePopupModel = <HomePopupModel>{};
  title = 'Qss-BitAlliance-Web';
  @ViewChild('content') content: any;
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private hpService: HomePopupService,
    ) {}
  ngOnInit() {
    this.showPopup();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Track navigation end
        rg4js('trackEvent', {
          type: 'pageView',
          path: event.url
        });
      } else if (event instanceof NavigationError) {
        // Track navigation error
        rg4js('send', {
          error: event.error
        });
      }
    });
  }

  showPopup()
  {
    this.hpService.getList().subscribe(res => {
      this.hp=res.find(x=> x.active);
      if(this.hp)
      {
        this.modalService.open(this.content, { centered: true, backdropClass: 'black' });
      }
    });
  }
}